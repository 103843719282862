section.dashboard {}

section.dashboard .hero {
    /* position */
    position: relative;

    /* sizing */
    width: 100%;
    height: 50vh;
    min-height: 300px;
    max-height: 600px;
}

section.dashboard .hero img {
    /* sizing */
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.dashboard .hero .welcome {
    /* position */
    position: absolute;
    inset: 0;
    right: auto;

    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    /* sizing */
    padding-left: 20px;

    /* styling */
    color: var(--primary-text-color);
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

section.dashboard .hero .welcome h1 {
    /* spacing */
    margin: 0;

    /* text */
    font-size: 54px;
    font-weight: 700;
}

section.dashboard .hero .welcome span {
    /* text */
    font-size: 16px;
    font-weight: 500;
}

section.dashboard .container {}
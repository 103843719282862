/**** modal ****/
section.modal {
    /* position */
    position: fixed;
    inset: 0;

    /* sizing */
    width: 100%;
    min-height: 100vh;

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;

    /* background */
    backdrop-filter: brightness(.8) blur(5px) opacity(1);
}

section.modal .container {
    /* sizing */
    height: fit-content;
    width: min(960px, calc(100% - max(8vw, 20px)));
    margin: 0 auto;
    padding: 10px;

    /* styling */
    border-radius: var(--modal-radius);
    backdrop-filter: brightness(.8) blur(15px);
}

section.modal .container .content {
    /* grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
    gap: 20px;
    padding: 10px;
}

/*** item type-2 ***/
section.modal .container .content .item.type-2 {
    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--modal-gap-500);

}

section.modal .container .content .item.type-2 .part {
    /* sizing */
    width: 100%;

    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--modal-gap-400);
}

section.modal .container .content .item.type-2 .part.fit {
    /* sizing */
    width: fit-content;
}

section.modal .container .content .item.type-2 .part .input {
    /* sizing */
    width: min(calc(var(--input-width) + var(--button-width) + (var(--modal-gap-400))), 100%);
    --input-width: 250px;
    --button-width: 80px;

    /* flex */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--modal-gap-400);
}

section.modal .container a.close:hover,
section.modal .container a.close:focus,
section.modal .container a.close:focus-visible {
    /* styling */
    color: var(--modal-color-invalid);
}
section.modal .container a.close:active {
    /* styling */
    color: var(--modal-color-selector);
}

section.modal .container .close svg {
    /* sizing */
    width: 100%;
    height: 100%;

    /* styling */
    fill: currentColor;
    color: inherit;
}

/*** images ***/
section.modal .container .content .avatar {
    /* sizing */
    --size: 75px;
    width: var(--size);
    height: var(--size);

    /* styling */
    border-radius: 50%;
}

/*** header ***/
section.modal .container .header {
    /* flex */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

section.modal .container .header h2 {
    /* spacing */
    margin: 0;

    /* text */
    font-size: 22px;
    font-weight: 700;

    /* styling*/
    color: var(--primary-text-color);
}

section.modal .container .header a.close {
    /* sizing */
    display: block;
    --size: 20px;
    --pad: 3px;
    height: var(--size);
    width: var(--size);
    padding: var(--pad);

    /* styling */
    color: var(--primary-text-color);
    border-radius: 50%;

    /* transition */
    transition: var(--preset-transition-color-300);
}

section.modal .container .content label {
    /* spacing */
    margin: 0;

    /* text */
    font-size: 14px;
    font-weight: 600;

    /* styling*/
    color: var(--primary-text-color);
}

/*** inputs ***/
/** input elements **/
section.modal .container .content input {
    /* sizing */
    padding: 0 10px;
    width: min(250px, 100%);
    height: 32px;

    /* flex */
    display: flex;
    flex-shrink: 0;
    justify-content: left;
    align-items: center;

    /* text */
    font-size: 14px;
    font-weight: 600;

    /* styling*/
    background: var(--tertiary-color);
    border: 2px solid transparent;
    border-radius: var(--modal-radius);
    color: var(--primary-text-color);
}

section.modal .container .content input::placeholder {    
    color: var(--quaternary-text-color);
}

/** buttons (& states) **/
section.modal .container .content button {
    /* spacing */
    margin: 0;

    /* sizing */
    padding: 0 10px;
    height: 32px;
    width: 80px;

    /* flex */
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    /* text */
    font-size: 14px;
    font-weight: 600;

    /* styling*/
    background: var(--tertiary-color);
    border: 2px solid transparent;
    border-radius: var(--modal-radius);
    color: var(--primary-text-color);
}

section.modal .container .content button:disabled {
    color: var(--quinary-text-color);
}

section.modal .container .content button:not(:disabled):hover,
section.modal .container .content button:not(:disabled):focus,
section.modal .container .content button:not(:disabled):focus-visible,
section.modal .container .content .file-input:hover,
section.modal .container .content .file-input:focus,
section.modal .container .content .file-input:focus-visible {
    background: var(--form-color-selector);
}

section.modal .container .content button:not(:disabled):active,
section.modal .container .content .file-input:active {
    background: transparent;
    color: var(--form-color-selector);
    border-color: currentColor;
}


/** file input **/
section.modal .container .content input[type="file"] {
    display: none;
}

section.modal .container .content .file-input {
    /* sizing */
    padding: 0 10px;
    width: min(250px, 100%);
    height: 32px;

    /* flex */
    display: flex;
    flex-shrink: 0;
    justify-content: left;
    align-items: center;

    /* text */
    font-size: 14px;
    font-weight: 600;

    /* styling*/
    background: var(--tertiary-color);
    border: 2px solid transparent;
    border-radius: var(--modal-radius);
    color: var(--primary-text-color);
}
/**** variables ****/
html {
    scroll-behavior: smooth;
    scroll-padding-top: 107px;
}

body {
    /* sizing */
    height: unset;

    /* spacing */
    margin: 0;
    overflow-x: hidden;

    /* styling */
    background-color: var(--secondary-color);

    /* transition*/
    transition: background-color var(--transition-duration) var(--transition-method);
}

body.noscroll {
    overflow: hidden !important;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

/*** Colors ***/
@media (prefers-color-scheme: light) {
    html {
        /* Colours */
        --primary-color: hsl(0, 0%, 100%);
        --secondary-color: hsl(0, 0%, 93%);
        --tertiary-color: hsl(0, 0%, 86%);
        --quaternary-color: hsl(45, 4%, 79%);
        --quinary-color: hsl(45, 4%, 72%);

        /* Text Colours */
        --primary-text-color: hsl(0, 0%, 0%);
        --secondary-text-color: hsl(0, 0%, 7%);
        --tertiary-text-color: hsl(0, 0%, 14%);
        --quaternary-text-color: hsl(45, 4%, 21%);
        --quinary-text-color: hsl(45, 4%, 28%);
        ;

        /* Image Colors */
        --image-background: #c7c7c7;
    }
}

@media (prefers-color-scheme: dark) {
    html {
        /* Colours */
        --primary-color: hsl(0, 0%, 0%);
        --secondary-color: hsl(0, 0%, 7%);
        --tertiary-color: hsl(0, 0%, 14%);
        --quaternary-color: hsl(0, 0%, 21%);
        --quinary-color: hsl(0, 0%, 28%);

        /* Text Colours */
        --primary-text-color: hsl(0, 0%, 100%);
        --secondary-text-color: hsl(0, 0%, 93%);
        --tertiary-text-color: hsl(0, 0%, 86%);
        --quaternary-text-color: hsl(0, 0%, 79%);
        --quinary-text-color: hsl(0, 0%, 72%);
        ;

        /* Image Colors */
        --image-background: #535353;
    }
}

/** Brand Colors **/
html {
    --brand-text-color: #f2eff6;
    --brand-selector-color: #0060df;
    --brand-selector-text-color: #000000;
}

@media (prefers-color-scheme: light) {
    html {
        /* Brand Colours */
        --brand-color-background: #A393BF;
        --brand-color-foreground: #9882AC;
    }
}

@media (prefers-color-scheme: dark) {
    html {
        /* Brand Colours */
        --brand-color-background: #453750;
        --brand-color-foreground: #73648A;
    }
}


/*** Transitions ***/
html {
    --transition-duration: 0.25s;
    --transition-method: ease-in-out;

    --transition-button-duration: 0.1s;
    --transition-button-method: ease-in-out;

    --backlog-text-transition: transform var(--transition-duration) var(--transition-method), color var(--transition-duration) var(--transition-method), text-shadow var(--transition-duration) var(--transition-method)
}

@media (prefers-reduced-motion) {
    html {
        --transition-duration: 0s;
        --transition-method: none;

        --transition-button-duration: 0s;
        --transition-button-method: none;
    }
}

/*** Radius ***/
html {
    --border-radius: 10px;
    --login-radius: var(--border-radius);
    --modal-radius: var(--border-radius);
    --menu-radius: var(--border-radius);
    --profile-radius: var(--border-radius);
    --hero-radius: var(--border-radius);
    --faq-radius: var(--border-radius);
    --social-radius: var(--border-radius);
    --footer-radius: var(--border-radius);
}

/*** Widths ***/
html {
    --page-max-width: 1500px;
    --page-width: min(var(--page-max-width), calc(100% - max(8vw, 20px)));
}

/*** Padding ***/
html {
    --padding: 10px;
    --header-padding-button: 0 calc(var(--padding) / 2);
}

/*** Shadows ***/
html {
    --text-shadow: 0 5px 10px black;
}

/*** Filters ***/
html {
    --hover-filter: brightness(.65) blur(3px) opacity(1);
    --drop-shadow: drop-shadow(0px 5px 15px black);
}

/*** forms ***/
html {
    --form-color-selector: #0060df;
    --form-color-invalid: #ff0000;
    --form-color-invalid-hold: #ffa500;
    --form-color-valid-hold: #00c000;
    --form-color-valid: #008000;
}

/*** transition ***/
html {
    --default-transform-duration: 0.25s;
    --default-transform-method: ease-in-out;
    --default-transform-duration-400: calc(var(--default-transform-duration) / 2);
    --default-transform-duration-300: calc(var(--default-transform-duration-400) / 2);

    --header-transform-duration: var(--default-transform-duration-400);
    --header-transform-method: var(--default-transform-method);

    --preset-transition-color: color var(--default-transform-duration) var(--default-transform-method);
    --preset-transition-background-color: background-color var(--default-transform-duration) var(--default-transform-method);
    --preset-transition-border: border var(--default-transform-duration) var(--default-transform-method);

    --preset-transition-color-400: color var(--default-transform-duration-400) var(--default-transform-method);
    --preset-transition-background-color-400: background-color var(--default-transform-duration-400) var(--default-transform-method);
    --preset-transition-border-400: border var(--default-transform-duration-400) var(--default-transform-method);
    --preset-transition-color-300: color var(--default-transform-duration-300) var(--default-transform-method);
    --preset-transition-background-color-300: background-color var(--default-transform-duration-300) var(--default-transform-method);
    --preset-transition-border-300: border var(--default-transform-duration-300) var(--default-transform-method);
}

/**** modals ****/
html {
    /*** gaps ***/
    --modal-gap-800: calc(var(--modal-gap-700) * 2);
    --modal-gap-700: calc(var(--modal-gap-600) * 2);
    --modal-gap-600: calc(var(--modal-gap-500) * 2);
    --modal-gap-500: 10px;
    --modal-gap-400: calc(var(--modal-gap-500) / 2);
    --modal-gap-300: calc(var(--modal-gap-400) / 2);
    --modal-gap-200: calc(var(--modal-gap-300) / 2);

    /* colors */    
    --modal-color-selector: #0060df;
    --modal-color-invalid: #ff0000;
    --modal-color-invalid-hold: #ffa500;
    --modal-color-valid-hold: #00c000;
    --modal-color-valid: #008000;
}
/**** background ****/
#defaultBackground {
    /* position */
    position: absolute;
    inset: 0;
    z-index: -1;

    /* overflow */
    overflow: clip;
}

#defaultBackground * {
    transition: background-color var(--transition-duration) var(--transition-method), color var(--transition-duration) var(--transition-method);
}

#defaultBackground .container {
    /* position */
    position: absolute;
    top: -50%;
    left: -50%;

    /* sizing */
    width: 200%;
    height: 200%;

    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    /* tranform */
    rotate: 25deg;
    transform: translateX(-50%);

    /* styling */
    backdrop-filter: none;
}

#defaultBackground p {
    --index-new: calc(var(--index) * 1);

    /* spacing */
    margin: 0;

    /* text */
    font-size: 40px;
    font-weight: 700;
    white-space: nowrap;

    /* transform */
    transform: translateX(calc(var(--index) * 100px));
    animation: backgroundScroll 450s infinite forwards ease-in-out;

    /* styling */
    color: var(--tertiary-color);
}

#defaultBackground p:nth-child(even) {
    --index-new: calc(var(--index) * -1);
}

@keyframes backgroundScroll {
    0%, 100% {
        /* transform */
        transform: translateX(calc(var(--index-new) * 75px));
    }

    50% {
        /* transform */
        transform: translateX(calc(var(--index-new) * -75px));
    }
}
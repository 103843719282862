/**** login ****/
section.login {
    /* position */
    position: relative;

    /* sizing */
    width: 100%;
    min-height: 100vh;

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;
}

section.login * {
    transition: background-color var(--transition-duration) var(--transition-method), color var(--transition-duration) var(--transition-method);
}

section.login .container {
    /* sizing */
    height: fit-content;
    width: min(500px, calc(100% - max(8vw, 20px)));
    margin: 0 auto;
    padding: 40px 10px;

    /* grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
    gap: 20px;

    /* styling */
    /* background-color: var(--secondary-color); */
    border-radius: var(--login-radius);
    backdrop-filter: brightness(.8) blur(15px) opacity(1);
}

section.login h2 {
    /* spacing */
    margin: 0;

    /* text */
    font-size: 25px;
    font-weight: 800;
    line-height: 1;

    /* styling */
    color: var(--primary-text-color);
}

section.login form,
section.login .alternate {
    /* position */
    position: relative;

    /* sizing */
    width: 100%;
    height: 100%;

    /* flex */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

/*** form ***/
section.login form .error {
    /* sizing */
    padding: 10px 20px;
    max-width: 400px;
    width: 100%;

    /* styling */
    background-color: rgb(255, 0, 0, .25);
    border: 2px solid red;
    border-radius: var(--login-radius);
    color: var(--primary-text-color);

    /* text */
    font-weight: 600;
    font-size: 16px;
}

section.login form .group {
    /* position */
    position: relative;

    /* sizing */
    width: min(400px, 100%);

    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 5px;
}

section.login form label {
    /* flex */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    /* text */
    font-size: 16px;
    font-weight: 700;

    /* styling */
    color: var(--secondary-text-color);
}

section.login form input {
    /* sizing */
    padding: 0 15px;
    width: min(400px, 100%);
    height: 40px;

    /* flex */
    display: flex;
    justify-content: left;
    align-items: center;

    /* text */
    font-size: 18px;
    font-weight: 600;

    /* styling */
    background: var(--tertiary-color);
    border: 2px solid transparent;
    border-radius: var(--login-radius);
    color: var(--primary-text-color);
}

section.login form input:focus {
    outline: 2px solid var(--form-color-selector);
    outline-offset: 5px;
}

section.login form input::placeholder {
    /* styling */    
    color: var(--primary-text-color);
}

section.login form input:valid:focus {
    border-color: var(--form-color-valid-hold);
}

section.login form input:valid:not(:focus) {
    border-color: var(--form-color-valid);
}

section.login form input:invalid:focus {
    border-color: var(--form-color-invalid-hold);
}

section.login form input:invalid:not(:focus),
section.login form input:not([value]) {
    border-color: var(--form-color-invalid);
}

section.login form span {
    /* spacing */
    margin: 0;
    padding: 0 5px;
    /* text */
    font-size: 14px;
    font-weight: 600;
    color: var(--tertiary-text-color);
    
}

/*** alternates ***/
section.login button {
    /* sizing */
    padding: 0 15px;
    width: min(400px, 100%);
    height: 40px;

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;

    /* text */
    font-size: 18px;
    font-weight: 600;

    /* styling */
    background: var(--tertiary-color);
    border: 2px solid transparent;
    border-radius: var(--login-radius);
    color: var(--primary-text-color);
}

section.login button:hover,
section.login button:focus,
section.login button:focus-visible {
    background: var(--form-color-selector);
}

section.login button:active {
    background: transparent;
    color: var(--form-color-selector);
    border-color: currentColor;
}
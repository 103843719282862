/**** user ****/
section.user {

}

section.user .container {
    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;
}


section.user img.avatar {
    /* sizing */
    --size: 300px;
    width: var(--size);
    height: var(--size);

    /* styling */
    border-radius: 50%;
}
/**** navbar ****/
html {
    /* variables */
    --header-padding: 10px;
    --header-width: var(--header-padding) min(calc(var(--header-padding) * 4), max(4vw, var(--header-padding)));
    --header-gap: 10px;
}

header {
    /* position */
    position: fixed;
    inset: 0;
    bottom: auto;
    z-index: 998;

    /* sizing */
    width: 100%;
    height: max-content;
}

header .container {
    /* sizing */
    padding: var(--header-width);
    height: 52px;

    /* flex */
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* styling */
    color: var(--primary-text-color);

    /* transistion */
    transition: background-color var(--transition-duration) var(--transition-method), color var(--transition-duration) var(--transition-method);
}

body.scrolled header .container {
    background-color: var(--brand-color-background);
    color: var(--brand-text-color);
}

#nav-btn {
    display: none;
}

header .nav {
    /* sizing */
    height: 100%;
    width: fit-content;

    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: calc(var(--header-gap) * 3);
}

header nav {
    /* flex */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
    align-items: center;
    gap: calc(var(--header-gap) * 3);

    /* sizing */
    height: 100%;
    margin: 0;
    padding: 0;

    /* list */
    list-style: none;
}

header a,
header button:not(.type-2) {
    /* position */
    position: relative;
    z-index: 1;

    /* sizing */
    height: 100%;
    padding: 0;
    padding: var(--header-padding-button);

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;

    /* text */
    color: inherit;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;

    /* styling */
    border: none;
    background-color: transparent;

    /* transistion */
    transition: var(--preset-transition-color);
}

header a:after,
header button:not(.type-2)::after {
    /* content */
    content: "";

    /* position */
    position: absolute;
    inset: 0;
    bottom: auto;
    top: calc(var(--header-padding) * -1);
    z-index: -1;

    /* sizing */
    width: 100%;
    height: calc(100% + var(--header-padding));

    /* tranform */
    transform: translateY(calc(-100% + 5px));
    transition: transform var(--header-transform-duration) var(--header-transform-method), var(--preset-transition-background-color);

    /* styling */
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: currentColor;
}

body.scrolled header a:after,
body.scrolled header button::after {
    background-color: var(--brand-text-color);
}

header .navMenu {
    /* display */
    display: block;

    /* sizing */
    height: 100%;
    width: fit-content;
    padding: calc(var(--header-padding) / 2);

    /* color */
    color: inherit;
    fill: currentColor;
    transition: fill var(--transition-duration) var(--transition-method);
}

header .navMenu:after {
    bottom: calc(var(--header-padding) * -1);
    left: calc((var(--header-padding) / 20) * -1);
}

header a:hover,
header a:focus,
header a:focus-visible,
header button:not(.type-2):hover,
header button:not(.type-2):focus,
header button:not(.type-2):focus-visible {
    /* text */
    color: var(--brand-color-background) !important;
}

header a:hover::after,
header a:focus::after,
header a:focus-visible::after,
header button:not(.type-2):hover::after,
header button:not(.type-2):focus::after,
header button:not(.type-2):focus-visible::after {
    /* tranform */
    transform: translateY(0);
    background-color: var(--primary-text-color);
}

header a:active,
header button:active {
    color: var(--brand-selector-text-color);
}

header a:active::after,
header button:active::after {
    background-color: var(--brand-selector-color);
}

header .navMenu svg

/*No Set Color*/
    {
    /* display */
    display: block;

    /* sizing */
    height: 100%;
    width: fit-content;
}

@media (max-width: 450px) {
    #nav-btn {
        display: block;
    }

    header nav {
        /* position */
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;

        /* sizing */
        padding: var(--padding) calc(var(--padding) * 4) !important;
        height: 100vh;
        width: 100vw;

        /* flex */
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        /* styling */
        background-color: var(--brand-color-background);

        /* hiding */
        pointer-events: none;
        opacity: 0;

        /* transition */
        transition: opacity 0.25s ease-in-out;
    }

    header nav.visible {
        opacity: 1;
        pointer-events: auto;
    }

    header .nav {
        /* flex */
        gap: var(--padding) !important;
    }

    header a:not(.navMenu),
    header button:not(.navMenu, .type-2) {
        /* position */
        position: relative;
        z-index: 1;

        /* sizing */
        height: fit-content;
        width: max-content;
        padding: 0;
        padding: var(--header-padding-button);

        /* flex */
        display: flex;
        justify-content: center;
        align-items: center;

        /* text */
        color: inherit;
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;

        /* styling */
        border: none;
        background-color: transparent;

        /* transistion */
        transition: var(--preset-transition-color);
    }

    header a:not(.navMenu):after,
    header button:not(.navMenu, .type-2)::after {
        /* content */
        content: "";

        /* position */
        position: absolute;
        inset: -5px;
        z-index: -1;

        /* sizing */
        width: calc(100% + var(--header-padding));
        height: calc(100% + var(--header-padding));

        /* tranform */
        opacity: 0;
        transform: translateY(0);
        transition: opacity var(--header-transform-duration) var(--header-transform-method), var(--preset-transition-background-color);

        /* styling */
        border-radius: 5px;
        background-color: var(--primary-text-color);
    }

    header a:not(.navMenu):hover:after,
    header button:not(.navMenu, .type-2):hover::after {
        opacity: 1;
        transform: translateY(0);
    }
}

/*** avatar ***/
header button.type-2 {
    /* sizing */
    --size: 32px;
    width: var(--size);
    height: var(--size);

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;

    /* styling */
    border: none;
    border-radius: 50%;
    background-color: transparent;

    /* transition */
    transition: var(--preset-transition-background-color);
}

header button.type-2:hover,
header button.type-2:focus,
header button.type-2:focus-visible {
    background-color: var(--primary-text-color);
}

header button.type-2:active {
    background-color: var(--brand-selector-color);
}

header button.type-2 .avatar {
    /* sizing */
    --size: 32px;
    width: var(--size);
    height: var(--size);

    /* styling */
    border-radius: 50%;

    /* transition */
    transition: scale var(--default-transform-duration) var(--default-transform-method);
}

header button.type-2:hover .avatar,
header button.type-2:focus .avatar,
header button.type-2:focus-visible .avatar {
    scale: 0.8;
}
/**** menu ****/
section.menu {
    /* position */
    position: fixed;
    inset: 0;
    pointer-events: none;
}

section.menu *:focus {
    /* focus state */
    outline-offset: 2px;
    outline-color: var(--brand-selector-color);
    outline-style: solid;
    outline-width: 2px;
}

section.menu .container {
    /* mouse */
    pointer-events: auto;

    /* position */
    position: absolute;
    top: 62px;
    right: min(calc(var(--header-padding) * 4), max(4vw, var(--header-padding)));
    z-index: 999;

    /* sizing */
    width: 200px;
    height: max-content;
    padding: 10px;

    /* flex */
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* styling */
    backdrop-filter: brightness(.64) blur(15px);
    border-radius: var(--menu-radius);

    /* hiding */
    transform: translateX(calc(100% + min(calc(var(--header-padding) * 4), max(4vw, var(--header-padding)))));
    transition: transform var(--default-transform-duration-400) var(--default-transform-method);;
}

section.menu.visible .container,
section.menu .container:focus-within {
    transform: translateX(0);
}

section.menu .part {
    /* sizing */
    width: 100%;
    height: max-content;

    /* flex */
    display: flex;
    flex-direction: column;
    gap: 5px;
}

section.menu .seperator {
    /* sizing */
    width: 100%;
    height: 5px;

    /* styling */
    border-radius: 100vmax;
    background-color: var(--primary-color);
}

/*** buttons ***/
/** default **/
section.menu button,
section.menu a {
    /* sizing */
    width: 100%;
    height: 32px;

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;

    /* text */
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;

    /* styling */
    border: 2px solid var(--tertiary-color);
    border-radius: var(--menu-radius);
    background-color: var(--tertiary-color);
    color: var(--primary-text-color);

    /* transitions */
    transition: var(--preset-transition-background-color-400), var(--preset-transition-color-400), var(--preset-transition-border-400);
}

section.menu button:hover,
section.menu button:focus,
section.menu button:focus-visible,
section.menu a:hover,
section.menu a:focus,
section.menu a:focus-visible {
    background: var(--form-color-selector);
    border: 2px solid var(--form-color-selector);
}

section.menu button:active,
section.menu a:active {
    background: transparent;
    color: var(--form-color-selector);
    border-color: currentColor;
}

/** logout **/
section.menu button.logout {
    /* styling */
    background-color: rgb(255, 0, 0, .25);
    border: 2px solid red;
}
section.menu button.logout:hover,
section.menu button.logout:focus,
section.menu button.logout:focus-visible {
    /* styling */
    background-color: rgb(255, 0, 0);
    outline-color: red;
}

section.menu button.logout:active {
    /* styling */
    background-color: white;
    border: 2px solid white;
    color: red;
}
/**** new post ****/
section.newPost {
    /* sizing */
    padding-top: 62px;
    min-height: 100vh;

    /* styling */
    color: var(--primary-text-color);
}

section.newPost .container {
    /* flex */
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
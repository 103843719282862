/**** logo ****/
body {    
    --logo-height: 32px;
}

.logo {
    /* sizing */
    box-sizing: border-box;
    width: fit-content;
    height: var(--logo-height);

    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.logo * {
    box-sizing: border-box;
}

.logo-border {
    /* position */
    position: relative;

    /* sizing */
    height: 100%;
    max-height: var(--logo-height);
    width: fit-content;

    /* pointer */
    pointer-events: none;

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-border::after {
    /* content */
    content: "";

    /* position */
    position: absolute;
    top: 0;

    /* sizing */
    display: block;
    width: 100%;
    height: 100%;

    /* styling */
    color: inherit;
    background-color: currentColor;

    /* animation */
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.25s ease-in-out;
}

.logo:hover .logo-border::after {
    /* animation */
    transform: scaleX(1);
    transform-origin: left;
}

.logo .end {
    /* sizing */
    width: 10px;
    height: 100%;

    /* styling */
    color: inherit;
    border: 3px solid currentColor;
}

.logo .end:first-of-type {
    /* styling */
    border-right: none !important;
}

.logo .end:last-of-type {
    /* styling */
    border-left: none !important;
}

.logo span {
    /* spacing */
    margin: 0;

    /* text */
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    color: inherit;
}
/**** feed ****//**** post ****/
section.feed {
    /* sizing */
    padding-top: 62px;
    min-height: 100vh;

    /* styling */
    color: var(--primary-text-color);
}

section.feed .container {
    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

section.feed .post {
    /* sizing */
    width: min(900px, calc(100% - 20px));

    /* styling */
    background-color: var(--tertiary-color);
    border-radius: 10px;
}

section.feed .post .header {
    /* sizing */
    width: 100%;
    height: 50px;
    padding: 10px;

    /* flex */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

section.feed .post .header .avatar {
    /* sizing */
    height: 30px;
    width: 30px;

    /* flex */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    /* styling */
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--primary-text-color);
}

section.feed .post .header .avatar img {
    /* sizing */
    width: 100%;
    height: 100%;

    /* styling */
    border-radius: 50%;
    overflow: hidden;

    /* transition */
    transition: scale var(--default-transform-duration) var(--default-transform-method);
}

section.feed .post .header .avatar:hover img {
    scale: 0.8;
}

section.feed .post .header .info {
    /* sizing */
    width: 100%;
    height: 100%;

    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* gap: 10px; */
}

section.feed .post .header .info span {
    /* sizing */
    max-height: 100%;
    max-width: 100%;

    /* spacing */
    margin: 0;

    /* text */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
}

section.feed .post .header .info .displayName {
    /* spacing */
    margin: 0;

    /* text */
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;

    /* styling */
    color: var(--quaternary-text-color);
}

section.feed .post .content {
    /* sizing */
    padding: 10px;
    padding-top: 0;
}

section.feed .post .content *:not(.images, .image, img) {
    /* spacing */
    margin: 0;
    line-height: 1;
}

section.feed .post .content h1 {
    /* text */
    font-size: 24px;
    font-weight: 700;
}

section.feed .post .content h2 {
    /* text */
    font-size: 20px;
    font-weight: 700;
}

section.feed .post .content h3 {
    /* text */
    font-size: 18px;
    font-weight: 700;
}

section.feed .post .content h4 {
    /* text */
    font-size: 17px;
    font-weight: 700;
}

section.feed .post .content h5 {
    /* text */
    font-size: 16px;
    font-weight: 700;
}

section.feed .post .content h6 {
    /* text */
    font-size: 15px;
    font-weight: 700;
}

section.feed .post .content a {
    /* text */
    color: var(--brand-selector-color);
    font-weight: 700;
}

section.feed .post *:focus {
    /* text */
    border-radius: 2px;
    outline: 2px solid var(--brand-selector-color);
    outline-offset: 2px;
}

section.feed .post .content p,
section.feed .post .content li {
    /* text */
    font-size: 16px;
    font-weight: 400;
}

section.feed .post .content ul {
    /* spacing */
    padding-left: 18.5px;
}

section.feed .post .content li {
    /* text */
    line-height: 1.2 !important;
}

section.feed .post .content .images {
    /* sizing */
    padding-top: 5px;
    width: 100%;
    height: 100%;

    /* grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(282px, 100%), 1fr));
    gap: 10px;

    /* styling */
    border-radius: 10px;
    overflow: clip;
}

section.feed .post .content .images .image {
    /* sizing */
    width: 100%;
    height: 100%;

    /* styling */
    border-radius: 10px;
    overflow: clip;
}

section.feed .post .content .images .image img,
section.feed .post .content .images .image video {
    /* sizing */
    max-width: 100%;
    max-height: 100%;

    /* styling */
    border-radius: 10px;
    overflow: clip;
}

section.feed .post .controls {
    /* sizing */
    padding: 10px;
    padding-top: 0;

    /* flex */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

section.feed .post .controls .section {
    /* flex */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}